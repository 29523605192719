import {
  Authenticated,
  useCreate,
  useCustom,
  useQueryClient,
  useRouterContext,
  useTranslate
} from "@pankod/refine-core"
import { useState } from "react"
import { IDeviceModel, IWorkspace } from "interfaces"
import customToast from "utilities/toastHelper"
import { LOG, Logger } from "utilities/logger"
import { Input } from "components/globals/Input"
import { Button } from "components/globals/Button"
import { useAppSelector } from "reduxStore/store"
import { DeviceImage } from "pages/devices/components/DeviceImage"
import { BackButton } from "components/icons"

export const DisplayCreate: React.FC = () => {
  const translate = useTranslate()
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  const { mutate } = useCreate()
  const [deviceName, setDeviceName] = useState("")
  const queryClient = useQueryClient()

  const workspaceId = useAppSelector((state) => state.workspace)

  const { data: workspaceData } = useCustom<IWorkspace>({
    url: `workspaces/${workspaceId}`,
    method: "get"
  })

  const { data: philipsModelData } = useCustom<IDeviceModel>({
    // Fixed id for rooms philips model
    url: `device-models/8`,
    method: "get"
  })

  function validate(): boolean {
    if (deviceName.length > 0) {
      return true
    }
    return false
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (validate()) {
      mutate(
        {
          resource: `devices/`,
          values: [
            {
              // ID 8 for philips room display
              device_model_id: `8`,
              name: `${deviceName}`,
              workspace_id: workspaceId
            }
          ]
        },
        {
          onError: (error, variables, context) => {
            customToast.error(
              translate("notifications.createError", {
                resource: translate("resources.device")
              })
            )
            void Logger().error(LOG.CREATE_DEVICE, `${error.stack}`)
          },
          onSuccess: (data, variables, context) => {
            push(`/devices/${data.data[0].id}`)
            customToast.success(
              translate("notifications.createSuccess", {
                resource: translate("resources.device")
              })
            )
          },
          onSettled() {
            setTimeout(
              () =>
                void queryClient.resetQueries({
                  queryKey: ["devicelist"]
                }),
              200
            )
          }
        }
      )
    }
  }

  return (
    <Authenticated>
      <div className="container mx-auto">
        <div className="mb-6 flex row h-12 items-center justify-between">
          <button
            onClick={() => {
              push("../displays")
            }}
          >
            <BackButton />
          </button>
          <h1 className="text-3xl font-medium">
            {translate("pages.devices.addNewDisplay")}
          </h1>
          <div></div>
        </div>
        <div className="flex flex-row justify-center content-center gap-2 my-6 border-b border-gray-200">
          <div className="w-80 flex-col">
            <div className="px-4 py-8 relative flex flex-col items-center justify-between border-2 rounded-md bg-white shadow-sm border-gray-100">
              <div className="flex-shrink-0">
                {philipsModelData && (
                  <DeviceImage type={philipsModelData.data.type} />
                )}
              </div>
              <div className="text-center">
                <p className="text-gray-600 truncate">
                  {philipsModelData?.data.type}
                </p>
                <p className="text-gray-600 truncate">
                  {philipsModelData?.data.name}
                </p>
              </div>
            </div>
          </div>
          <div className="w-144 py-4 relative flex flex-col justify-between border-2 rounded-md bg-white shadow-sm border-gray-100">
            <div className="divide-y-2 divide-slate-300/25">
              <form
                className="form px-4"
                onSubmit={onSubmit}
                autoComplete="off"
              >
                <Input
                  label="Device name"
                  id="test"
                  value={deviceName}
                  onChange={(e) => setDeviceName(e.target.value as string)}
                />
                {workspaceData && (
                  <div>
                    <p>{translate("pages.devices.willBeAddedTo")}</p>
                    <p className="font-medium">{workspaceData?.data.name}</p>
                  </div>
                )}
                <div className="flex justify-between">
                  <Button type="submit" name={translate("buttons.save")} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Authenticated>
  )
}
